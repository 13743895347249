<template>
  <div class="img-cut">
    <el-dialog
      title="图片裁剪"
      :visible="dialogVisible"
      @close="close"
      @opened="openInit"
      width="1200px"
    >
      <vue-cropper
        v-if="dialogVisible"
        ref="cropper"
        :img="imageSrc"
        v-bind="option"
      ></vue-cropper>
      <!-- <button>裁剪图片</button> -->
      <div slot="footer">
        <el-button @click="close">取 消</el-button>
        <el-button
          type="primary"
          @click="cropImage"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { VueCropper } from 'vue-cropper';
export default {
  props: {
    imageSrc: {
      type: String,
      default: '',
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    cutImgParams: {
      type: Object,
      default: () => {},
    },
    fileMsg: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    VueCropper,
  },
  data() {
    return {
      option: {
        img: this.imageSrc, // 裁剪图片的地址 url 地址, base64, blob
        outputSize: 1, // 裁剪生成图片的质量
        outputType: 'png', // 裁剪生成图片的格式 jpeg, png, webp
        info: true, // 裁剪框的大小信息
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        // fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
        canMove: true, // 上传图片是否可以移动
        canMoveBox: true, // 截图框能否拖动
        original: true, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        full: true, // 是否输出原图比例的截图
        mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
        high: false,
        maxImgSize: 1000,
        enlarge: '1',
        canScale: true,
      },
    };
  },
  methods: {
    // 初始化
    openInit() {
      this.option.img = this.imageSrc;
      this.option = { ...this.option, ...this.cutImgParams };
    },
    close() {
      this.$emit('update:dialogVisible', false);
    },
    cropImage() {
      return this.$refs.cropper.getCropData((data) => {
        console.log(data);
        // this.base64ToImageFile(data, this.width, this.height, this.option.outputType).then(res=>{})
        console.log(this.f_dataURLtoFile(data, this.fileMsg.name, this.option.outputType));
        // return;
        this.$emit(
          'uploadImg',
          this.f_dataURLtoFile(data, this.fileMsg.name.replace(/\.jpe?g$/i, '.png'), this.option.outputType),
          this.fileMsg.key,
        );
        this.close();
      });
    },
    // base64转file
    f_dataURLtoFile(dataURL, fileName, type, size) {
      // console.log(dataURL)
      const arr = dataURL.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      console.log(new File([u8arr], fileName, { type }));
      return new File([u8arr], fileName, { type });
    },
    //base64 转化成固定宽高的文件
    base64ToImageFile(base64String, width, height, fileName) {
      return new Promise((resolve, reject) => {
        // 创建一个Image对象
        const img = new Image();

        // 设置Image对象的源为Base64字符串
        img.src = `data:image/png;base64,${base64String}`;

        img.onload = () => {
          // 创建一个Canvas元素
          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          // 获取Canvas的2D上下文
          const ctx = canvas.getContext('2d');
          // 绘制图像到Canvas上，并调整大小
          ctx.drawImage(img, 0, 0, width, height);
          // 将Canvas内容转换为Blob
          canvas.toBlob((blob) => {
            if (blob) {
              // 创建一个文件对象
              const file = new File([blob], fileName, { type: 'image/png' });
              resolve(file);
            } else {
              reject(new Error('Failed to convert canvas to Blob'));
            }
          }, 'image/png');
        };

        img.onerror = (error) => {
          reject(error);
        };
      });
    },
  },
};
</script>
<style lang="scss">
.img-cut .el-dialog__body {
  height: 860px !important;
}
.img-cut .el-dialog {
  max-height: none !important;
}
</style>
